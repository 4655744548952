/* support for IE11 β */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

/* general */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

/* aws */
import Amplify from "aws-amplify";

/* redux & router */
import { Provider } from 'react-redux';
import { createBrowserHistory } from "history";
import { ConnectedRouter } from 'connected-react-router';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore as reduxCreateStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from 'redux-thunk';

/* reducer */
//import { locationReducer } from './reducers/Location'
import { accountReducer } from './reducers/Account'

const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const authConfig = {
  region: process.env.REACT_APP_COGNITO_AWS_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
};

Amplify.configure({
  Auth: authConfig
});

const store = reduxCreateStore(
  combineReducers(
    {
      /* stete for router */
      router: connectRouter(history),
      /* account */
      account: accountReducer,
      /* location */
      //location: locationReducer,
    }
  ),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history), thunk
    ))
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

/*
serviceWorker.unregister();
*/
