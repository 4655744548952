/* react */
import React from 'react';

/* material-ui/style */
import { makeStyles } from '@material-ui/core/styles';

/* material-ui/core */
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/* user component */
import { DisplayAlert } from '../DisplayAlert.js'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop : '30px',
    marginBottom : '1px',
    backgroundColor: theme.palette.primary.main,
  },
  Typography: {
    marginTop: '10px',
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    height: '45px'
  },
}));

export default function ChangePassword(props) {
  const classes = useStyles();

  function onChangeNewPassword(e) {
    props.onChangeNewPassword(e.target.value);
  }

  function handleChangePassword() {
    props.clickChangePassword(props.account.loginUser, props.account.newPassword);
  }

  /* Close function on Alert Dialog */
  function closeLoginAlert() {
    props.closeLoginAlert();
  }

  /* Popup Alert Dialog */
  const responseInfo = DisplayAlert(
    props.account.strVariant,
    props.account.showInform,
    closeLoginAlert,
    props.account.msgInform
  );

  return (
    <Container component="main" maxWidth="xs">
      {responseInfo}
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h5" variant="h5">
            Location Monitoring System
        </Typography>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5" variant="h5">
          パスワード変更
        </Typography>
        <Typography component="h6" variant="h6" className={classes.Typography}>
          User ID: {props.account.user}
        </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Enter New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChangeNewPassword} 
            value={props.account.newPassword}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleChangePassword}
          >
            変更
          </Button>
        </div>
    </Container>
  );
}