/* react */
import React from 'react';

/* material-ui/style */
import { makeStyles } from '@material-ui/core/styles';

/* material-ui/core */
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  appBar: {
    top: 'auto',
    height: '18px',
    bottom: 0,
    boxShadow: 'none',
    marginRight: '5px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
},
}));

export default function Types() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Typography variant="caption" display="block" gutterBottom align='right'>
            ©ALPINE ELECTRONICS, INC./ALPINE ELECTRONICS MARKETING, INC.
        </Typography>
      </AppBar>
    </div>
  );
}
