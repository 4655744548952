/* react */
import React from 'react';

/* react router */
import { Route, Link } from "react-router-dom";

/* material-ui/style */
import { makeStyles, useTheme } from '@material-ui/core/styles';

/* material-ui/core */
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

/* material-ui/icons */
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AndroidIcon from '@material-ui/icons/Android';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import MouseIcon from '@material-ui/icons/Mouse';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

/* etc */
import PropTypes from 'prop-types';

/* user component */
import Location from './Location/Location'
import Operation from "./Help/Operation";
import Setup from "./Help/Setup";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    padding: '0px'
  },
  titleListItem: {
    padding: '0px',
    marginBottom: '10px'
  },
  titleTypography: {
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
  },
  accountIcon: {
    marginRight: '-5px'
  },
  content: {
    flexGrow: 1,
    paddingTop :'0px',
    paddingLeft:'1px',
    paddingRight: '1px',
    paddingBottom: '1px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function Dashboard(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    props.width === 'xs' || props.width === 'sm' ? setMobileOpen(!mobileOpen) : setMobileOpen(mobileOpen) ;
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const drawer = (
    <div>
      <List>
        <ListItem className={classes.titleListItem}>
          <Typography variant="subtitle2" color='primary' className={classes.titleTypography} align='center' gutterBottom >
            Location Monitoring System
          </Typography>
        </ListItem>
        <ListItem button component={Link} to="/location" onClick={handleDrawerToggle}> 
          <ListItemIcon><PhoneIphoneIcon /></ListItemIcon>
          <ListItemText>
              端末一覧
          </ListItemText>
        </ListItem>
{/*
        <ListItem button component={Link} to="/help" >
          <ListItemIcon><HelpIcon /></ListItemIcon>
          <ListItemText>
            ヘルプ
          </ListItemText>
        </ListItem>
*/}
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="ヘルプ" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/help/setup" onClick={handleDrawerToggle}>
              <ListItemIcon>
                <AndroidIcon />
              </ListItemIcon>
              <ListItemText primary="アプリ初期設定" />
            </ListItem>
          </List>
        </Collapse>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={Link} to="/help/operation" onClick={handleDrawerToggle}>
              <ListItemIcon>
                <MouseIcon />
              </ListItemIcon>
              <ListItemText primary="操作方法" />
            </ListItem>
          </List>
        </Collapse>
        </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const isMenuOpen = Boolean(anchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  
  function handleMenuClose() {
    setAnchorEl(null);
  }
  /* currently disabled 
  function handleMyAccount() {
    setAnchorEl(null);
    props.history.push(`/branch/1/accountdetail/sub001`);
  }
  */
  function handleSignOut() {
    setAnchorEl(null);
    props.clickLogout();
  }

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <center>
        <AccountCircleIcon />
        {props.account.user}
      </center>
      {/*<MenuItem onClick={handleMyAccount}>マイアカウント</MenuItem> */}
      <MenuItem onClick={handleSignOut} style={{ fontSize: '14px' }}>サインアウト</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color='primary'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.logo} align='center'>
            <img src="ALPINE-LOGO.png" alt="logo"/>
          </Typography>
          <IconButton
            className={classes.accountIcon}
            color="inherit"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <AccountCircleIcon />
          </IconButton>
          
        </Toolbar>
      </AppBar>
      {renderMenu}
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
          <Route exact path='/' component={Location} />
          <Route path="/Location" component={Location} />
          <Route path="/help/setup" component={Setup} />
          <Route path="/help/operation" component={Operation} />
      </main>
    </div>
  );
}

Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

//export default Dashboard;
export default withWidth()(Dashboard);