/*-----------------------------------------
      ACTION COMPONENT: location
  -----------------------------------------*/

import axios from "axios";
import { addAuthorizationHeader } from '../Account'

export async function getDeviceLocationListData() {
  try {
    const URL = `${process.env.REACT_APP_API_URL_BASE}${'device/list'}`
    const KEY = process.env.REACT_APP_API_KEY
    const header_auth = await addAuthorizationHeader({})
    if (header_auth === 'Session error') {
      console.error('Session error')
    }
    const header = {
      'authorization': header_auth.Authorization,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    const res = await axios.get(URL, { headers: header })
    return await res
  } catch (err) {
    console.error(err);
  }
}

export async function putDeviceLabel(updateTargetIMEI, managementNameTemp) {
  try {
    const URL = `${process.env.REACT_APP_API_URL_BASE}${'device/label'}`
    const KEY = process.env.REACT_APP_API_KEY
    const data = {
      'imei': updateTargetIMEI,
      'name_label': managementNameTemp
    }
    const header_auth = await addAuthorizationHeader({})
    if (header_auth === 'Session error') {
      console.error('Session error')
      throw header_auth
    }
    const header = {
      'authorization': header_auth.Authorization,
      'Content-Type': 'application/json'
    }
    const res = await axios.put(URL, data, { headers: header });
    return await res
  } catch (err) {
    console.error(err);
  }
}

export function getWindowSize() {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth,
    height = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: width,
    height: height
  }
}