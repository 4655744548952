/* react */
import React from 'react';

/* material-ui/style*/
import { withStyles } from "@material-ui/core/styles";

/* material-ui/core */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

/* action componet */
import { getDeviceLocationListData } from '../../../../../actions/Location/locationAction';

/* leaflet */
import {
  Map,
  TileLayer,
  Marker,
  LayersControl,
} from 'react-leaflet'
import { iconShop } from '../Leaflet/LeafletIcons';

/* etc */
import moment from 'moment';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '@media (min-width:768px)': {      
      flexGrow: 1,
      padding: '0px',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minWidth: '100%',
      height: '100%',
    },
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    height: 120,
    /* ie11*/
    '@media(-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: 160,
      width: 500
    },
  },
  paperRow: {
    padding: theme.spacing(0),
    minWidth: '100%',
  },
  paperCellLabel: {
    padding: theme.spacing(1),
    display: 'inline-block',
    width: 150,
    verticalAlign: 'top',
    height: '30px',
    '@media (max-width:825px)': {
      width: 90,
      fontSize: '12px',
      padding: '0px',
      height: '10px'
    },
  },
  paperCellValue: {
    padding: theme.spacing(1),
    display: 'inline-block',
    width: 250,
    verticalAlign: 'top',
    height: '30px',
    fontSize: '14px',
    '@media (max-width:825px)': {
      width: '65%',
      fontSize: '12px',
      padding: '0px',
      height: '10px'
    },
  },
  paperLeaflet: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 350,
    '@media (max-height:415px) and (max-width:825px)': {
      height: 300
    },
    '@media (max-height:700px) and (max-width:415px)': {
      height: 275
    },
    '@media (max-height:600px) and (max-width:415px)': {
      height: 200
    },
    '@media (max-height:400px) and (max-width:830px)': {
      height: 250
    },
    /* ie11 */
    '@media(-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: 350,
      width: 500,
    },
  },
  toolbarButtons: {
    width: 90,
    marginTop: '10px',
  },
})

/* need to relay function info between Location.js to LocationLatest.js. due to enable return button fuction */
let handleWindowStateDispatch = null;
const handleWindowState = (windowState) => {
  handleWindowStateDispatch(windowState);
}

class LocationLatest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDevice: [],
      position_center: [38.258595, 137.6850225],
      zoomValue: 4,
    }
  }

  componentDidMount() {
    this.setDisplayDevice(this.props.data.state_clickDeviceInfo[0])
    this.setPosition(
      this.props.data.state_clickDeviceInfo[0].location_device.lat,
      this.props.data.state_clickDeviceInfo[0].location_device.lng,
      this.props.data.state_clickDeviceInfo[0].location_shop.lat, 
      this.props.data.state_clickDeviceInfo[0].location_shop.lng
    )

    /* if not smart phone, not need to call api. because api on table is available */
    if (this.props.data.state_window === 'tabs' & this.props.data.state_windowSize_width < 768 ) {
      this.interval = setInterval(() => this.getDeviceLocationList(), 60000);
      this.setState({ 'setInterval': true })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setDisplayDevice(nextProps.data.state_clickDeviceInfo[0])
    this.setPosition(
      nextProps.data.state_clickDeviceInfo[0].location_device.lat,
      nextProps.data.state_clickDeviceInfo[0].location_device.lng,
      nextProps.data.state_clickDeviceInfo[0].location_shop.lat,
      nextProps.data.state_clickDeviceInfo[0].location_shop.lng
    )
  }

  componentWillUnmount() {
    this.setState({ 'setInterval': false })
    clearInterval(this.interval);
  }

  getDeviceLocationList = () => {
    getDeviceLocationListData()
      .then(resDeviceLists => {
        for (var i in resDeviceLists.data) {
          if (this.props.data.state_clickDeviceInfo[0].id_device !== null) {
            if (this.props.data.state_clickDeviceInfo[0].id_device === resDeviceLists.data[i].id_device) {
              this.setDisplayDevice(resDeviceLists.data[i])
              this.setPosition(
                Number(resDeviceLists.data[i].location_device.lat),
                Number(resDeviceLists.data[i].location_device.lng),
                Number(resDeviceLists.data[i].location_shop.lat),
                Number(resDeviceLists.data[i].location_shop.lng),
              )
            }
          } 
        }
      }
    ).catch(err => {
      console.log('err:', err);
    })
  }

  setDisplayDevice(inputProps) {
    const displayDevice = {
      id_device: inputProps.id_device,
      num_device: inputProps.num_device,
      id_customer: inputProps.id_customer,
      name_label: inputProps.name_label,
      name_company: inputProps.name_company,
      name_shop: inputProps.name_shop,
      timestamp: inputProps.timestamp,
      location_shop_lat: Number(inputProps.location_shop.lat),
      location_shop_lng: Number(inputProps.location_shop.lng),
      location_device_lat: Number(inputProps.location_device.lat),
      location_device_lng: Number(inputProps.location_device.lng),
    }
    this.setState({ 'displayDevice': displayDevice })
  }

  setPosition(location_device_lat, location_device_lng, location_shop_lat, location_shop_lng) {
    /* define device/shop/center position*/
    const position_device = [location_device_lat, location_device_lng]
    const position_shop = [location_shop_lat, location_shop_lng]
    this.setState({ 'position_device': position_device })
    this.setState({ 'position_shop': position_shop })

    this.setState({ 'position_device_lat': location_device_lat})
    this.setState({ 'position_device_lng': location_device_lng })

    /* not to set center position/zoom when device's lat or lng is 0*/
    if (location_device_lat === 0 || location_device_lng === 0) {
      const position_center = [location_shop_lat, location_shop_lng]
      this.setState({ 'position_center': position_center })
      this.setState({ 'zoomValue': 6 })
    } else {  
      const latMax = Math.max(location_shop_lat, location_device_lat)
      const latMin = Math.min(location_shop_lat, location_device_lat)
      const lngMax = Math.max(location_shop_lng, location_device_lng)
      const lngMin = Math.min(location_shop_lng, location_device_lng)
      const latCenter = Number(latMin + (latMax - latMin) / 2)
      const lngCenter = Number(lngMin + (lngMax - lngMin) / 2)
      const position_center = [latCenter, lngCenter]
      this.setState({ 'position_center': position_center })

      /* define zoom level */
      const latDiff = latMax - latMin
      const lngDiff = lngMax - lngMin

      if (latDiff > lngDiff) {
        this.setState({ 'zoomValueFlag': 1 });
        latDiff <= 0.01 ? this.setState({ 'zoomValue': 17 }) : latDiff <= 0.05 ? this.setState({ 'zoomValue': 13 }) :
            latDiff <= 0.1 ? this.setState({ 'zoomValue': 10 }) : latDiff <= 1 ? this.setState({ 'zoomValue': 8 }) :
              latDiff <= 3 ? this.setState({ 'zoomValue': 6 }) : this.setState({ 'zoomValue': 4 });
      } else if (latDiff < lngDiff) {
        this.setState({ 'zoomValueFlag': 1 });
        lngDiff <= 0.01 ? this.setState({ 'zoomValue': 17 }) : lngDiff <= 0.05 ? this.setState({ 'zoomValue': 13 }) :
            lngDiff <= 0.1 ? this.setState({ 'zoomValue': 10 }) : lngDiff <= 1 ? this.setState({ 'zoomValue': 8 }) :
              lngDiff <= 3 ? this.setState({ 'zoomValue': 6 }) :  this.setState({ 'zoomValue': 4 })
      }
    }
  }

  render() {
    const {classes} = this.props
    const { BaseLayer, Overlay } = LayersControl;
    
    /* define to function */
    handleWindowStateDispatch = this.props.data.handleWindowState;

    const rows = [
      { label: "管理番号", value: this.state.displayDevice.num_device },
      { label: "管理名", value: this.state.displayDevice.name_label },
      { label: "会社名", value: this.state.displayDevice.name_company },
      { label: "店舗名", value: this.state.displayDevice.name_shop },
      { label: "最終取得日時", value: this.state.displayDevice.timestamp },
    ];

    const renderRow = (row) => {
      return (
        <Paper key={row.label} className={classes.paperRow} elevation={0} square>
          <Grid item xs={12}>
            <Paper className={classes.paperCellLabel} elevation={0} square>
              {row.label}
            </Paper>
            <Paper className={classes.paperCellValue} elevation={0} square>
              {row.label === "最終取得日時" ?
                row.value !== null ? moment(row.value).format('YYYY/MM/DD HH:mm:ss') : '-' 
                 : row.value
              }
            </Paper>
          </Grid>
        </Paper>
      );
    }

    return (
    <div className={classes.root}>
        <Grid
          container spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={5} md={5} lg={5} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <Grid container item spacing={1} >
                {rows.map((row, index) => {
                  return (
                    renderRow(row, index)
                  );
                })}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={7} md={7} lg={7} xl={8}>
            <Paper className={classes.paperLeaflet} elevation={0}>
              <Map center={this.state.position_center} zoom={this.state.zoomValue}>
                <LayersControl position="topright">
                  <BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                      attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                      url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                    />
                  </BaseLayer>
                  <BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                      attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </BaseLayer>

                  {this.state.position_device_lat !== 0 || this.state.position_device_lng !== 0?
                  <Overlay checked name="端末">
                    <Marker position={this.state.position_device} />
                  </Overlay>
                    : <div></div>}
                  <Overlay checked name="店舗">
                    <Marker position={this.state.position_shop} icon={iconShop} />
                  </Overlay>
                </LayersControl>
              </Map>
            </Paper>
            {this.props.data.state_windowSize_width < 768 ?
              <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                <Button align='right' variant="contained" color="secondary" className={classes.toolbarButtons} onClick={() => { handleWindowState('table') }}>
                  一覧に戻る
                </Button>
              </Grid> : <div></div>}
            </Grid>
        </Grid>
    </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(LocationLatest);
