const textLabels = {
  body: {
//    noMatch: "データがありません。",
    noMatch: "Now Loading...",
    toolTip: "ソート",
  },
  pagination: {
    next: "次ページ",
    previous: "前ページ",
    rowsPerPage: "Page:",
    displayRows: "/",
  },
  toolbar: {
    search: "検索",
    downloadCsv: "CSVダウンロード",
    print: "印刷",
    viewColumns: "表示列",
    filterTable: "フィルター",
  },
  filter: {
    title: "フィルター",
    reset: "リセット",
  }
}

export default textLabels;
