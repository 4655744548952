import L from "leaflet";

export const iconShop = new L.Icon({
    iconUrl: require('../../../../../img/iconfinder_go-home_118770.png'),
    iconRetinaUrl: require('../../../../../img/iconfinder_go-home_118770.png'),
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    popupAnchor: [0, 0],
})

