/* react */
import React from 'react';

/* material-ui/style */
import { makeStyles } from '@material-ui/core/styles';

/* material-ui/core */
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

/* user component */
import AreaDataTable from './AreaDataTable/AreaDataTable';
import AreaTab from "./AreaTab/AreaTab";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperCarList: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 300,
  },
  paperDeviceInfo: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    '@media (max-width:825px)': {
      padding: '0px',
    },
  },
  fixedHeight: {
    height: 280,
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();

  const [state_window, setWindowState] = React.useState('table');
  const [state_window_width, setWindowWidth] = React.useState(null);
  const [state_window_height, setWindowHeight] = React.useState(null);
  const [state_id_device, setDeviceId] = React.useState(null);
  const [state_clickDeviceInfo, setclickDeviceInfo] = React.useState(null);
  const [state_selectedRowsIndex, setSelectedRowsIndex] = React.useState(null);
  const [state_selectedRowsInfo, setSelectedRowsInfo] = React.useState(null); 

  const handleWindowState = (windowState) => {
    setWindowState(windowState);
  }

  const handleInitDevice = (id_device) => {
    setDeviceId(id_device);
  }
  const handleClickDevice = (
      e,
      id_device,
      clickDeviceInfo,
      WindowSize
    ) => {
    setDeviceId(id_device);
    setclickDeviceInfo(clickDeviceInfo);
    setWindowWidth(WindowSize.width)
    setWindowHeight(WindowSize.height)
  }

  const handleSelectDevice = (selectedRowsIndex, selectedRowsInfo) => {
    setSelectedRowsIndex(selectedRowsIndex);
    setSelectedRowsInfo(selectedRowsInfo);
  }

  return (
    <React.Fragment>
      <main className={classes.content}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={1}>
            {/* DataTable */}
            {state_window === 'table' & state_window_width === null ? // <--- initial 
              <Grid item sm={12} xs={12}>
                {/* <Paper className={classes.paperDeviceList}> */}
                <AreaDataTable
                  handleClickDevice={handleClickDevice}
                  handleSelectDevice={handleSelectDevice}
                  handleInitDevice={handleInitDevice}
                  handleWindowState={handleWindowState}
                  state_id_device={state_id_device}
                />
              </Grid> : 
                 state_window === 'tabs' & state_window_width > 767 ? // <-- for PC/iPad
                  <Grid item sm={12} xs={12}>
                    {/* <Paper className={classes.paperDeviceList}> */}
                  <AreaDataTable
                    handleClickDevice={handleClickDevice}
                    handleSelectDevice={handleSelectDevice}
                    handleInitDevice={handleInitDevice}
                    handleWindowState={handleWindowState}
                    state_id_device={state_id_device}
                  />
                  </Grid> : 
                  state_window === 'table' & state_window_width < 767 ? // <-- for SmartPhone
                    <Grid item sm={12} xs={12}>
                      {/* <Paper className={classes.paperDeviceList}> */}
                    <AreaDataTable
                      handleClickDevice={handleClickDevice}
                      handleSelectDevice={handleSelectDevice}
                      handleInitDevice={handleInitDevice}
                      handleWindowState={handleWindowState}
                      state_id_device={state_id_device}
                    />
                    </Grid> : <div></div> } {/* <-- for SmartPhone when device clicked. > Hide Talbe & Tab only displayed */}
            {/* Device Information Display */}
            {state_window === 'tabs' ?            
                <Grid item sm={12} xs={12}> 
                  {state_id_device != null ?
                    <Paper className={classes.paperDeviceInfo}>
                      <AreaTab
                        handleWindowState={handleWindowState}
                        state_clickDeviceInfo={state_clickDeviceInfo} 
                        state_windowSize_width={state_window_width}
                        state_windowSize_height={state_window_height}
                        state_window={state_window}
                      />
                  </Paper> : <div></div>}
                </Grid> : <div></div>}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}

export default Dashboard;
