/*---------------------------------------------------
     CONTAINER COMPONENT: Auth
---------------------------------------------------*/

/* redux */
import { connect } from 'react-redux'

/* Action functions */
import { onChangeUser, onChangePassword, clickLogin, forgotPassword, forgotPasswordSubmit, closeLoginAlert } from '../../actions/Account'

/* presentational Components */
import Login from '../../components/Contents/Login/Login'

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeUser: (user) => dispatch(onChangeUser(user)),
    onChangePassword: (password) => dispatch(onChangePassword(password)),
    clickLogin: (user, password) => dispatch(clickLogin(user, password)),
    forgotPassword: (username) => dispatch(forgotPassword(username)),
    forgotPasswordSubmit: (username, code, new_password) => dispatch(forgotPasswordSubmit(username, code, new_password)),
    closeLoginAlert: () => dispatch(closeLoginAlert())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
