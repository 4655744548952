/*---------------------------------------------------
      CONTAINER COMPONENT: CheckChangePassword
---------------------------------------------------*/

/* react */
import React from 'react'

/* react-router/redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

/* presentational components */
import { ChangePasswordCompornent } from '../../App'

class CheckChangePassword extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		if (this.props.login) {
			this.props.history.push('/')
		} else if (!this.props.needChangePassword) {
			this.props.history.push('/login')
		}
	}

	render() {
		let compornent = <br />
		if (!this.props.isFetching) {
			compornent = <ChangePasswordCompornent />
		}
		return (
			<div>
				{compornent}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.account.login,
		needChangePassword: state.account.needChangePassword
	}
}

export default withRouter(connect(mapStateToProps)(CheckChangePassword))
