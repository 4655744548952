/*---------------------------------------------------
      CONTAINER COMPONENT: ChangePassword
---------------------------------------------------*/

/* react */
import { connect } from 'react-redux'

/* action functions */
import { onChangeUser, onChangeNewPassword, clickChangePassword, closeLoginAlert } from '../../actions/Account'

/* presentational components */
import ChangePassword from '../../components/Contents/Login/ChangePassword'

const mapStateToProps = (state) => {
	return state
}

const mapDispatchToProps = (dispatch) => {
	return {
		onChangeUser: (user) => dispatch(onChangeUser(user)),
		onChangeNewPassword: (password) => dispatch(onChangeNewPassword(password)),
		clickChangePassword: (user, newpassword) => dispatch(clickChangePassword(user, newpassword)),
		closeLoginAlert: () => dispatch(closeLoginAlert())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ChangePassword)
