/* react */
import React from 'react';

/* materia-ui/style */
import { makeStyles } from '@material-ui/core/styles';

/* material-ui/core */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    hight: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
  paperTitleSub: {
    padding: theme.spacing(0),
  },
  paperBody: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  paperImage:{
    textAlign: 'center',
  }
}));

export default function Types() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} >
        {/* title */}
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0} square>
            <Typography variant="h3">
              アプリ初期設定方法
            </Typography>
          </Paper>
        </Grid>
        
        {/* title-sub-1 */}
        <Grid item xs={12}>
          <Paper className={classes.paperTitleSub} elevation={0} square>
            <Typography variant="h6">1.アプリ起動方法</Typography>
          </Paper>
        </Grid>

        {/* row 1-1 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_11.png" alt="qr-code" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
              <Typography variant="body2">
                1-1. 位置情報を取得したい携帯電話から、アプリ起動ページを開いてください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 1-2 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_12.png" alt="phone12" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              1-2. 「車両管理アプリを開く」ボタンをタップして、車両管理アプリを起動します<br/>
              <br />
            </Typography>
            <Typography variant="body2" color='error'>
              【注意】<br />
              「車両管理アプリを開く」ボタンをタップしても、何も反応が無い場合はアプリがインストールされていない可能性があります。<br />
              「Toneストア」アプリから「車両管理」アプリをインストールしてください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 1-3 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_13.png" alt="phone13" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              1-3. 車両管理アプリを起動すると、位置情報へのアクセス許可が求められます。許可をタップしてください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 1-4 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_14.png" alt="phone14" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              1-4.車両管理アプリを起動すると、電話の発信と管理の許可が求められます。許可をタップしてください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 1-5 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_15.png" alt="phone15" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              1-5.アプリ画面上に、15桁の番号が表示されていることをご確認ください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 1-6 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_16.png" alt="phone16" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              1-6. 以上で初期設定が終わりましたので、携帯電話端末を再起動してください。
              </Typography>
          </Paper>
        </Grid>


        {/* title-sub-2 */}
        <Grid item xs={12}>
          <Paper className={classes.paperTitleSub} elevation={0} square>
            <Typography variant="h6">2.電源設定</Typography>
          </Paper>
        </Grid>

        {/* row 2-1 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_21.png" alt="phone21" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-1. 電源設定を変更します。メニューから設定を開いてください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 2-2 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_22.png" alt="phone22" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-2. 設定から電池を開いてください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 2-3 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_23.png" alt="phone23" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-3. 電池の設定画面の、メニューを開いてください
              </Typography>
          </Paper>
        </Grid>

        {/* row 2-4 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_24.png" alt="phone24" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-4. メニューから電池の最適化を開いてください
              </Typography>
          </Paper>
        </Grid>

        {/* row 2-5 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_25.png" alt="phone25" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-5. 電池の最適化画面上部にある、「最適化していない」をタップしてください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 2-6 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_26.png" alt="phone26" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-6. 「すべてのアプリ」を選択してください。
              </Typography>
          </Paper>
        </Grid>

        {/* row 2-7 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_27.png" alt="phone27" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-7. アプリの一覧から、「車両管理」を選択してください
              </Typography>
          </Paper>
        </Grid>

        {/* row 2-8 */}
        <Grid item xs={12} sm={12} md={3}>
          <Paper className={classes.paperImage} elevation={0} square >
            <img src="/manual/img_manual_28.png" alt="phone28" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              2-8. 「最適化しない」を選択し、「完了」をタップしてください
              </Typography>
          </Paper>
        </Grid>
        
      </Grid>
    </div>
  );
}
