/* react */
import React, { PureComponent } from 'react';

/* react-router */
import {Link} from 'react-router-dom';

/* material-ui/core */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

/* materi-ui/icons */
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EditIcon from '@material-ui/icons/Edit';

/* mui-datatables */
import MUIDataTable from "mui-datatables";
import TextLabels from "./MuiDTTextLabels";

/* action component */
import { getDeviceLocationListData, putDeviceLabel, getWindowSize } from '../../../../../actions/Location/locationAction'

/* user component */
import CustomToolbar from "./CustomToolbar";

/* etc */
import moment from 'moment';

/* update window size info to other componet like TabMap via Dashboard */
let handleWindowStateDispatch = null;
const handleWindowState = (windowState) => {
  handleWindowStateDispatch(windowState);
}

/* update clicked device info on mui-datatables to other componet like TabMap via Dashboard */
let handleClickDeviceDispatch = null; 
const handleClickDevice = (
  e,
  id_device,
  num_device,
  id_customer,
  name_label, 
  name_company, 
  name_shop,
  timestamp,
  location_shop_lat, 
  location_shop_lng,
  location_device_lat, 
  location_device_lng,
  WindowSize
  ) => {
  const clickDeviceInfo = [{
    'id_device': id_device,
    'num_device': num_device,
    'id_customer': id_customer,
    'name_label': name_label,
    'name_company': name_company,
    'name_shop': name_shop,
    'timestamp': timestamp,
    'location_shop' :{
      'lat': location_shop_lat,
      'lng': location_shop_lng
    },
    'location_device' :{
      'lat': location_device_lat,
      'lng': location_device_lng
    }
  }]
  handleClickDeviceDispatch(
    e,
    id_device,
    clickDeviceInfo,
    WindowSize,
  );
}

/* update checked devices info on mui-datatables to other componet like TabMap via Dashboard */
let handleSelectDeviceDispatch = null; 
const handleSelectDevice = (selectedRowsIndex, selectedRowsInfo) => {
  handleSelectDeviceDispatch(selectedRowsIndex, selectedRowsInfo);
}

export default class AreaDataTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      RowPerPage: 5,
      TabDisplayedDevice: null,
      RowsSelected: [],
      columnSortDirection: ["none", "none", "none", "none", "desc"],
      filterdata: [],
      filterList: [[], [], [], [], [], [], [], [], [], []],
      dialogOpen: false,
      managementNameCurrent: null,
      managementNameTemp: null,
      updateTargetIMEI: null,
      WindowSize: {
        width: null,
        height: null,
      },
      WindowState: {
        table: true,
        tab: false,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /* queue & wait are neccesary avoid performance problem */ 
    var queue = null
    var wait = 300
    window.addEventListener('resize', () => {
      clearTimeout(queue);
      queue = setTimeout( () => {
        this.setWindowSize();
      }, wait);
    }, false);
    }

  /* disabe because of componentWillMount will be not supported
  componentWillMount() {
    var size = getWindowSize()
    this.setState({ 'WindowSize': size });
    size.width <= 768 ? this.setState({ 'RowPerPage': 3 }) : this.setState({ 'RowPerPage': 5 })
    
    window.addEventListener('resize', () => {
      var size = getWindowSize()
      this.setState({ 'WindowSize': size });
      size.width <= 768 ? this.setState({ 'RowPerPage': 3 }) : this.setState({ 'RowPerPage': 5 })
    })
  }
  */

  componentDidMount() {
    this.setWindowSize()
    this.getDeviceLocationList()
    this.interval = setInterval(() => this.getDeviceLocationList(), 60000); 
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setWindowSize = () => {
    var size = getWindowSize()
    this.setState({ 'WindowSize': size });
  }

  getDeviceLocationList = () => {
    getDeviceLocationListData()
      .then(resDeviceLists => {
        const arrayDeviceLists = [];

        for (var i in resDeviceLists.data) {
          /* store to state */
          arrayDeviceLists.push({
            id_device: resDeviceLists.data[i].id_device,
            num_device: resDeviceLists.data[i].num_device,
            id_customer: resDeviceLists.data[i].id_customer,
            name_label: resDeviceLists.data[i].name_label,
            name_company: resDeviceLists.data[i].name_company,
            name_shop: resDeviceLists.data[i].name_shop,
            location_shop: {
              lat: Number(resDeviceLists.data[i].location_shop.lat),
              lng: Number(resDeviceLists.data[i].location_shop.lng)
            },
            location_device: {
              lat: Number(resDeviceLists.data[i].location_device.lat),
              lng: Number(resDeviceLists.data[i].location_device.lng)
            },
            timestamp: resDeviceLists.data[i].timestamp,
          })

          /* announce to TabMap */
          if (this.state.TabDisplayedDevice !== null) {
            if (this.state.TabDisplayedDevice === resDeviceLists.data[i].id_device) {
              handleClickDevice(
                'e',
                resDeviceLists.data[i].id_device,
                resDeviceLists.data[i].num_device,
                resDeviceLists.data[i].id_customer,
                resDeviceLists.data[i].name_label,
                resDeviceLists.data[i].name_company,
                resDeviceLists.data[i].name_shop,
                resDeviceLists.data[i].timestamp,
                Number(resDeviceLists.data[i].location_shop.lat),
                Number(resDeviceLists.data[i].location_shop.lng),
                Number(resDeviceLists.data[i].location_device.lat),
                Number(resDeviceLists.data[i].location_device.lng),
                this.state.WindowSize
              )
            } 
          } 
        }
        this.setState({ 'DeviceList': arrayDeviceLists });
      }
      ).catch(err => {
        console.log('err:', err);
      })
  }

  handleClickOpen = () => {
    this.setState({ 'dialogOpen' : true });
  };

  handleCloseCancel = () => {
    this.setState({ 'dialogOpen' : false });
    this.setState({ 'managementNameTemp' : "" });
  };

  handleCloseSubmit = () => {
    this.setState({ 'dialogOpen': false });   
    const arrayDeviceLists = [];

    if (this.state.managementNameCurrent === this.state.managementNameTemp) {
      console.log('samte string. not need to update label')
    } else {
      /* update name_label value */ 
      for (var i in this.state.DeviceList) {
        /* update device list data */
        arrayDeviceLists.push({
          id_device: this.state.DeviceList[i].id_device,
          num_device: this.state.DeviceList[i].num_device,
          id_customer: this.state.DeviceList[i].id_customer,
          name_label: 
            this.state.DeviceList[i].id_device === this.state.updateTargetIMEI ? this.state.managementNameTemp : this.state.DeviceList[i].name_label,
          name_company: this.state.DeviceList[i].name_company,
          name_shop: this.state.DeviceList[i].name_shop,
          location_shop: {
            lat: Number(this.state.DeviceList[i].location_shop.lat),
            lng: Number(this.state.DeviceList[i].location_shop.lng)
          },
          location_device: {
            lat: Number(this.state.DeviceList[i].location_device.lat),
            lng: Number(this.state.DeviceList[i].location_device.lng)
          },
          timestamp: this.state.DeviceList[i].timestamp,
        })
      }
      this.setState({ 'DeviceList': arrayDeviceLists });
      putDeviceLabel(this.state.updateTargetIMEI, this.state.managementNameTemp)
      .then(res => {
          //console.log(res)
        })
        .catch(err => {console.log('putDeviceLabel:', err);}
      )
    }
  };

  setManagementNameTemp = (event) => {
    this.setState({ 'managementNameTemp': event.target.value });
  }

  setUpdateTargetIMEI = (id_device) => {
    this.setState({ 'updateTargetIMEI': id_device });
  }

  setInitialLabelValue_Current_and_Temp = (value) => {
    this.setState({ 'managementNameCurrent': value })
    this.setState({ 'managementNameTemp': value })
  }

  handleTabDisplayedDevice = (id_device) => {
    this.setState({ 'TabDisplayedDevice': id_device })
  }

  xhrRequest = url => {
    let page = 0;
    let order = "";
    let column = "";
    if (url !== undefined) {
      page = url.page;
      order = url.order;
      column = url.column;
    }
    return new Promise((resolve, reject) => {
      const srcData = this.state.DeviceList
      var offset = page * 10;
      var data = [];

      if (order !== "") {
        if (order === "asc") {
          var tempData = srcData.sort((a, b) => {
            return a[3] - b[3];
          });
          data =
            offset + 10 >= srcData.length
              ? tempData.slice(offset, srcData.length)
              : tempData.slice(offset, offset + 10);
        } else {
          tempData = srcData.sort((a, b) => {
            return b[3] - a[3];
          });
          data =
            offset + 10 >= srcData.length
              ? tempData.slice(offset, srcData.length)
              : tempData.slice(offset, offset + 10);
        }
      } else {
        data =
          offset + 10 >= srcData.length
            ? srcData.slice(offset, srcData.length)
            : srcData.slice(offset, offset + 10);
      }

      setTimeout(() => {
        resolve(data);
      }, 250);
    });
  };

  /* column setting for keeping sort direction */
  sortColumn = (column, order) => {
    let temp = {};
    temp.column = column;
    temp.order = order;
    temp.page = this.state.page;
    let newColumnSortDirections = ["none", "none", "none", "none", "none"];
    switch (column) {
      case "num_device":
        newColumnSortDirections = [order, "none", "none", "none", "none"];
        break;
      case "name_label":
        newColumnSortDirections = ["none", order, "none", "none", "none"];
        break;
      case "name_company":
        newColumnSortDirections = ["none", "none", order, "none", "none"];
        break;
      case "name_shop":
        newColumnSortDirections = ["none", "none", "none", order, "none"];
        break;
      case "timestamp":
        newColumnSortDirections = ["none", "none", "none", "none", order];
        break;
      default:
        break;
    }
    newColumnSortDirections[column.index] = order;
    this.xhrRequest(temp).then(data => {
      this.setState({
        data,
        columnSortDirection: newColumnSortDirections
      });

    });
  }

  render() {
    let init_id_device = this.props.id_device;

    if (isNaN(init_id_device)) {
      init_id_device = null;
    }

    /* execute only first time */
    if (this.props.state_id_device === null) {
      handleClickDeviceDispatch = this.props.handleClickDevice;
      handleSelectDeviceDispatch = this.props.handleSelectDevice;
      handleWindowStateDispatch = this.props.handleWindowState;
      this.props.handleInitDevice(init_id_device);
    }

    /* define columns */
    const { filterList } = this.state;
    let columns = [
      {
        name: "id_device",
        label: "id_device",
        options: {
          filter: false,
          sort: false,
          display: 'false',   /* <--- not display. only for include to tableMeta.rowData */
          viewColumns: false,
          searchable: false,
        }
      },
      {
        name: "num_device",
        label: "管理番号",
        options: {
          sortDirection: this.state.columnSortDirection[0],
          filterList: filterList[1].length ? filterList[1] : null,
          filter: true,
          sort: true,
          display: true,
          viewColumns: false,
          searchable: true,
          customBodyRender: (value, tableMeta) => {
            let id_device = null;
            let num_device = null;
            let id_customer = null;
            let name_label = null;
            let name_company = null;                    
            let name_shop = null;
            let location_shop_lat = null;
            let location_shop_lng = null;
            let location_device_lat = null;
            let location_device_lng = null;
            let timestamp = null;
            id_device = tableMeta.rowData[0];
            num_device = tableMeta.rowData[1];
            id_customer = tableMeta.rowData[2];
            name_label = tableMeta.rowData[3];
            name_company = tableMeta.rowData[4];
            name_shop = tableMeta.rowData[5];
            timestamp = tableMeta.rowData[6];
            location_shop_lat = tableMeta.rowData[7];
            location_shop_lng = tableMeta.rowData[8];
            location_device_lat = tableMeta.rowData[9];
            location_device_lng = tableMeta.rowData[10];
            return (
              <Link style={{ color: '#00549a' }} onClick={e => {
                handleClickDevice(
                  e, 
                  id_device,
                  num_device,
                  id_customer,
                  name_label, 
                  name_company, 
                  name_shop,
                  timestamp,
                  location_shop_lat,
                  location_shop_lng,              
                  location_device_lat, 
                  location_device_lng,
                  this.state.WindowSize
                );
                handleWindowState('tabs');
                this.handleTabDisplayedDevice(id_device);
              }}>
                <PhoneIphoneIcon />
                {value}
              </Link>
            );
          }
        }
      },
      {
        name: "id_customer",
        label: "id_customer",
        options: {
          filter: false,
          sort: false,
          display: 'false',   /* <--- not display. only for include to tableMeta.rowData */
          viewColumns: false,
          searchable: false,
        }
      },
      {
        name: "name_label",
        label: "管理名",
        options: {
          sortDirection: this.state.columnSortDirection[1],
          filterList: filterList[3].length ? filterList[3] : null,
          filter: true,
          sort: true,
          display: true,
          viewColumns: false,
          searchable: true,
          customBodyRender: (value, tableMeta) => {
            let id_device = null;
            let name_label = null;
            id_device = tableMeta.rowData[0];
            name_label = tableMeta.rowData[2];
            return (
              <div>
                <IconButton onClick={() => { 
                  this.handleClickOpen();
                  this.setUpdateTargetIMEI(id_device);
                  this.setInitialLabelValue_Current_and_Temp(value);
                }}>
                  <EditIcon fontSize='small'/>
                </IconButton >
                <Dialog
                  open={this.state.dialogOpen} onClose={this.handleCloseCancel} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">管理名 編集画面</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      管理名の編集後に「編集完了」ボタンを押してください。
                </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="管理名"
                    type="text"
                    fullWidth
                    defaultValue={this.state.managementNameCurrent}
                    //index={tableMeta.columnIndex}
                    onChange={event => { this.setManagementNameTemp(event) }}
                  />
                </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined" 
                      size="small" 
                      color="disable"
                      //index={tableMeta.columnIndex}                    
                      onClick={event => { this.handleCloseCancel(event) }} 
                      >
                      キャンセル
                  </Button>
                    <Button 
                      variant="contained"
                      size="small" 
                      color='secondary'
                      //index={tableMeta.columnIndex}
                      onClick={event => { this.handleCloseSubmit(event) }} 
                      >
                      編集完了
                    </Button>
                  </DialogActions>
                </Dialog>
                {value}
              </div>
            );
          }
        }
      },
      {
        name: "name_company",
        label: "会社名",
        options: {
          sortDirection: this.state.columnSortDirection[2],
          filterList: filterList[4].length ? filterList[4] : null,
          filter: true,
          sort: true,
          display: true,
          viewColumns: false,
          searchable: false,
        }
      },
      {
        name: "name_shop",
        label: "店舗名",
        options: {
          sortDirection: this.state.columnSortDirection[3],
          filterList: filterList[5].length ? filterList[5] : null,
          filter: true,
          sort: true,
          display: true,
          viewColumns: false,
          searchable: false,
        }
      },
      {
        name: "timestamp",
        label: "最終取得日時",
        options: {
          sortDirection: this.state.columnSortDirection[4],
          filter: false,
          sort: true,
          display: true,
          viewColumns: false,
          searchable: false,
          customBodyRender: (value) => {
            return (
                <div>
                {value !== null ? moment(value).format('YYYY/MM/DD HH:mm:ss') : <center>-</center>}
              </div>
            );
          }
        }
      },
      {
        name: "location_shop.lat",
        label: "lat_shop",
        options: {
          filter: false,
          sort: false,
          display: false,   // <--- not display. only for include to tableMeta.rowData
          viewColumns: false,
          searchable: false,
        }
      },
      {
        name: "location_shop.lng",
        label: "lng_shop",
        options: {
          filter: false,
          sort: false,
          display: false,   // <--- not display. only for include to tableMeta.rowData
          viewColumns: false,
          searchable: false,
        }
      },
      {
        name: "location_device.lat",
        label: "lat_device",
        options: {
          filter: false,
          sort: false,
          display: false,   // <--- not display. only for include to tableMeta.rowData
          viewColumns: false,
          searchable: false,
        }
      },
      {
        name: "location_device.lng",
        label: "lng_device",
        options: {
          filter: false,
          sort: false,
          display: false,   /* <--- not display. only for include to tableMeta.rowData */
          viewColumns: false,
          searchable: false,
        }
      },
    ];

      const options = {
        responsive: 'stacked',
        rowsPerPage: this.state.RowPerPage,
        rowsPerPageOptions: [3, 5, 10, 15, 20, 30],
        elevation: 1,
        search: false,
        viewColumns: false,
        print: false,
        download: false,
        textLabels: TextLabels,
        selectableRows: 'multiple', /* display CheckBox */ 
        rowsSelected: this.selectedRowsIndex, 
        disableToolbarSelect: true, /* not display multi selection ToolBar */

        customToolbar: () => {
          return <CustomToolbar data={this.state.RowsSelected} windowSize={this.state.WindowSize}/>;
        },

        onColumnSortChange: (changedColumn, direction) => {
          let order = "desc";
          if (direction === "ascending") {
            order = "asc";
          }
          this.sortColumn(changedColumn, order);
        },

        onFilterChange: (changedColumn, filterList) => {
          const filterdata = [];
          this.setState({ filterdata, filterList });
        },
        
        onRowsSelect: (currentRowsSelected, allRowsSelected) => {
          /* get id of slected row */
          const selectedRowsIndexToState = allRowsSelected.map(item => {
            return item.dataIndex;  /* ge id_device  ->  update to rowSelected */
          });
          this.selectedRowsIndex = selectedRowsIndexToState;

          /* get infomation of selected row */
          const selectedRowsInfoToState = allRowsSelected.map(item => {
            return this.state.DeviceList[item.dataIndex];  /* get device info of all selected device */
          });
          this.selectedRowsInfo = selectedRowsInfoToState;

          this.setState({ 'RowsSelected': this.selectedRowsInfo });
          handleSelectDevice(this.selectedRowsIndex, this.selectedRowsInfo);
        },
      }

    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MUIDataTable
              title={`端末一覧`}
              data={this.state.DeviceList}
              columns={columns}
              options={options}
              index='1'
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
