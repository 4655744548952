/*---------------------------------------------------
      CONTAINER COMPONENT: CheckLogin
---------------------------------------------------*/

/* react */
import React from 'react'

/* react-router/redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { LoginCompornent } from '../../App'

/* presentational components */
class CheckLogin extends React.Component {
    componentDidMount() {
        if (this.props.login) {
            this.props.history.push('/')
        } else if (this.props.needChangePassword) {
            this.props.history.push('/change-password')
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.login) {
            this.props.history.push('/')
        } else if (this.props.needChangePassword) {
            this.props.history.push('/change-password')
        }
    }

    render() {
        let compornent = <br />
        if (!this.props.isFetching) {
            compornent = <LoginCompornent />
        }
        return (
            <div>
                {compornent}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.account.isFetching,
        login: state.account.login,
        needChangePassword: state.account.needChangePassword
    }
}

export default withRouter(connect(mapStateToProps)(CheckLogin))
