/* react */
import React from 'react';

/* react-router */
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

/* material-ui/style */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes, { overrides } from './themes';

/* material-ui/core */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

/* user componet */
import ChangePassword from './containers/Auth/ChangePassword';
import CheckChangePassword from './containers/Auth/CheckChangePassword';
import CheckLogin from './containers/Auth/CheckLogin';
import CheckRoot from './containers/Auth/CheckRoot';
//import Contents from './containers/Contents';
import Dashboard from './containers/Dashboard/Dashboard';
import Footer from './components/Footer/Footer';
import Login from './containers/Auth/Login';

/* leaflet */
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css';

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

const theme = createMuiTheme({
  ...themes.default, ...overrides,
});

export class LoginCompornent extends React.Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <Grid container spacing={0}>
              <Grid item sm={1} md={2} lg={3} />
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Login />
              </Grid>
              <Grid item sm={1} md={2} lg={3} />
            </Grid>
            <Grid>
              <Footer />
            </Grid>
          </React.Fragment>
        </MuiThemeProvider>
      </div>
    )
  }
}

export class ChangePasswordCompornent extends React.Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <Grid container spacing={0}>
              <Grid item sm={1} md={2} lg={3} />
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <ChangePassword />
              </Grid>
              <Grid item sm={1} md={2} lg={3} />
            </Grid>
            <Grid>
              <Footer />
            </Grid>
          </React.Fragment>
        </MuiThemeProvider>
      </div>
    )
  }
}

export class GeneralCompornent extends React.Component {
  render() {
    return (
      <div >
        <MuiThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <Dashboard /> 
            <Grid>
              <Footer />
            </Grid>
          </React.Fragment>
        </MuiThemeProvider >
      </div>
    )
  }
}

export class NotFound extends React.Component {
  render() {
    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid>
            <Typography variant="h2" align='center'>
              404
              </Typography>
            <Typography variant="h5" align='center'>
              Not Found
              </Typography>
          </Grid>
        </Grid>
      </div>
    )    
  }
}

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={CheckRoot} />
          <Route exact path="/login" component={CheckLogin} />
          <Route exact path="/change-password" component={CheckChangePassword} />
          <Route exact path="/location" component={CheckRoot} />
          <Route exact path="/help/setup" component={CheckRoot} />
          <Route exact path="/help/operation" component={CheckRoot} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

export default App;
