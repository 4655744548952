/*---------------------------------------------------
      CONTAINER COMPONENT: Dashboard
---------------------------------------------------*/

/* react-redux*/
import { connect } from 'react-redux'

/* action component */
import {
  clickLogout,
  replacePasswordShow, replacePasswordField, replacePasswordHide, clickReplacePassword,
  replaceResultHide
} from '../../actions/Account'

/* Presentational Components */
import Dashboard from '../../components/Contents/Dashboard/Dashboard'

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    clickLogout: () => dispatch(clickLogout()),
    clickChangePassword: () => dispatch(replacePasswordShow()),
    replacePasswordField: (key, value) => dispatch(replacePasswordField(key, value)),
    clickClosePassword: () => dispatch(replacePasswordHide()),
    clickReplacePassword: (oldpass, newpass) => dispatch(clickReplacePassword(oldpass, newpass)),
    clickReplaceResult: () => dispatch(replaceResultHide())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
