/* react */
import React from 'react'

/* material-ui/style */
import { makeStyles } from '@material-ui/core/styles';

/* material-ui/core */
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function DisplayAlert(stringVariant, flagShowResponse, handleCloseResponse, responseMessages) {
  const classes = useStyles();

  let target = "success";

  if (stringVariant === "success") {
    target = "success";
  }
  if (stringVariant === "failure") {
    target = "error";
  }

  return (
    <div className={classes.root}>
      {flagShowResponse === true ? <Alert severity={target} onClose={() => { handleCloseResponse() }}>{responseMessages}</Alert> : <div></div>}
    </div>
  );
}