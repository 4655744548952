/* react */
import React from 'react';

/* material-ui/style */
import { makeStyles } from '@material-ui/core/styles';

/* material-ui/core */
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

/* material-ui/icons */
import InfoIcon from '@material-ui/icons/Info';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

/* etc */
import PropTypes from 'prop-types';

/* user component */
import LocationLatest from './LocationLatest'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography style={{ padding: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '@media (min-width:768px)': {
      flexGrow: 1,
      padding: '0px',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minWidth: '100%',
      height: '100%',
    },
  },
  AppBar:{
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '@media (max-height:415px) and (max-width:825px)': {
      display: 'none',
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.primary.main,
    minWidth: '15%',
  },
}));

export default function AreaTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      { props.state_windowSize_width >= 768 ?
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          className={classes.tabs}
          >
          <Tab label="端末位置" icon={<InfoIcon style={{ fontSize: 33 }} />}  {...a11yProps(0)} /> 
        </Tabs> : 
        <AppBar position="static" color="background.whight" className={classes.AppBar}>
            <Tabs value={value} textColor="primary" onChange={handleChange} aria-label="simple tabs example">
              <Tab label="端末位置" icon={<PhoneIphoneIcon style={{ fontSize: 20 }} />}  {...a11yProps(0)} />
            </Tabs>
          </AppBar>
        }
        <TabPanel value={value} index={0}>
          <LocationLatest data={props} />
        </TabPanel>      
    </div>
  );
}
