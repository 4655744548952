/* react */
import React from 'react';

/* materia-ui/style */
import { withStyles } from "@material-ui/core/styles";

/* material-ui/core */
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

/* action component */
import { getWindowSize } from '../../../../actions/Location/locationAction';

/* etc */
import AnchorLink from 'react-anchor-link-smooth-scroll';

const styles = theme => ({
  root: {
    hight: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  paperTitleSub: {
    padding: theme.spacing(0),
  },
  paperBody: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  paperImage:{
    textAlign: 'center',
  },
  titleImage: {
    width: '100%',
    maxWidth: 850,
  },
  titleImageSX: {
    width: '100%',
    maxWidth: 370,
  },
  titleImageSX2: {
    width: '100%',
    maxWidth: 180,
  },
})

class Operation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WindowSize: {
        width: null,
        height: null,
      },
    };
  }

  componentDidMount() {
    this.setWindowSize()
  }

  componentDidUpdate(prevProps, prevState) {
    /* queue & wait are neccesary avoid performance problem */
    var queue = null
    var wait = 300
    window.addEventListener('resize', () => {
      clearTimeout(queue);
      queue = setTimeout(() => {
        this.setWindowSize();
      }, wait);
    }, false);
  }

  setWindowSize = () => {
    var size = getWindowSize()
    this.setState({ 'WindowSize': size });
  }

  render() {
    const { classes } = this.props

  return (
    <div className={classes.root}>
      <Grid container spacing={2} >
        {/* title */}
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0} square>
            <Typography variant="h3">
              操作方法
            </Typography>
          </Paper>
        </Grid>
        
        {/* title-sub-1 */}
        <Grid item xs={12}>
          <Paper className={classes.paperTitleSub} elevation={0} square>
            <Typography variant="h6">1.基本画面</Typography>
          </Paper>
        </Grid>

        {/* row 1-1 */}
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {this.state.WindowSize.width > 750 & this.state.WindowSize.height > 450 ?  // for iPad/PC
            <Paper elevation={0} square >
              <img src="/manual/img_ope_11.png" alt="baseview" className={classes.titleImage}/>
            </Paper>
            : // for SmartPhone
            <Paper className={classes.paperImage} elevation={0} square > 
              <img src="/manual/img_ope_11_xs.png" alt="baseview" className={classes.titleImageSX} />
            </Paper>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper className={classes.paperBody} elevation={0} square>
              <Typography variant="body2">
                ① メニュー画面<br/>
              {this.state.WindowSize.width < 450 & this.state.WindowSize.height < 850 ?  // for SmartPhone
                <Typography variant="body2">
                  &emsp;&emsp;クリックするとメニューが表示されます。
                </Typography>:
                this.state.WindowSize.width < 850 & this.state.WindowSize.height < 450 ?  // for SmartPhone
                  <Typography variant="body2">
                    &emsp;&emsp;クリックするとメニューが表示されます。
                </Typography>:<div></div>}
                &emsp;&emsp;表示したいメニューを選択して下さい。<br />
                ② 端末一覧表示画面 (最終取得時間は定期的に更新)<br />
                &emsp;&emsp;稼働中の端末の一覧を表示します。<br />
                ③ 端末情報表示画面 (最終取得時間は定期的に更新)<br />
                &emsp;&emsp;管理番号をクリックした端末の情報を表示します。<br />
                {this.state.WindowSize.width < 450 & this.state.WindowSize.height < 850 ?  // for SmartPhone
                  <Typography variant="body2">
                  &emsp;&emsp;※.チェック後画面上部中央のマップアイコンをクリック<br />
                  </Typography> :
                  this.state.WindowSize.width < 850 & this.state.WindowSize.height < 450 ?  // for SmartPhone
                    <Typography variant="body2">
                    &emsp;&emsp;※.チェック後画面上部中央のマップアイコンをクリック<br />
                    </Typography> :
                    <Typography variant="body2">
                      &emsp;&emsp;※.チェック後画面右上のマップアイコンをクリック<br />
                    </Typography>
                }
                ④ サインイン状態アイコン<br />
                &emsp;&emsp;・使用中のアカウント名が確認できます。<br />
                &emsp;&emsp;・<AnchorLink href="#link4" offset="50">サインアウト</AnchorLink>する時に使用します。<br />
                ⑤ 管理番号<br />
                &emsp;&emsp;クリックすると端末情報表示画面「③」に<br />
                &emsp;&emsp;端末情報が表示されます。<br />
                ⑥<AnchorLink href="#link1" offset="50"> 複数端末位置表示機能<br /></AnchorLink>
                &emsp;&emsp;チェックした端末の位置と店舗を地図表示します。<br />
                ⑦<AnchorLink href="#link2" offset="50"> 管理名編集機能<br /></AnchorLink>
                &emsp;&emsp;クリックすると管理名編集画面が表示されます。<br />
                ⑧<AnchorLink href="#link3" offset="50"> フィルター機能<br /></AnchorLink>
                &emsp;&emsp;任意にフィルターを設定し端末を表示できます。<br />
                ⑨ ページ表示行数/ページ移動<br />
                &emsp;&emsp;・1ページに表示する行数を指定できます。<br />
                &emsp;&emsp;・&lt; &gt;でページ移動ができます。<br />
            </Typography>
            <Hidden smUp>
              <Typography variant="body2">
                  &emsp;※.表示されない時は下にスクロールして下さい。
              </Typography>
            </Hidden>
          </Paper>
        </Grid>

        {/* title-sub-2 */}
        <Grid item xs={12}>
          <Paper className={classes.paperTitleSub} elevation={0} square>
            <Typography variant="h6" id="link1">2.複数端末位置表示機能</Typography>
          </Paper>
        </Grid>
        {/* row 2-1 */}
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {this.state.WindowSize.width > 750 & this.state.WindowSize.height > 450 ?  // for iPad/PC
            <Paper elevation={0} square >
              <img src="/manual/img_ope_21.png" alt="multi" className={classes.titleImage} />
            </Paper>
            : // for SmartPhone
            <Paper className={classes.paperImage} elevation={0} square >
              <img src="/manual/img_ope_21_xs.png" alt="multi" className={classes.titleImageSX2} />
            </Paper>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              任意に選択した複数の端末と所属店舗の位置を地図に表示します。<br />
              <br />
              使用方法：<br />
              基本画面で表示したい端末に<img src="/manual/img_ope_21_mark_check.png" alt="check" />をして下さい。<br />
            </Typography>
              {this.state.WindowSize.width < 450 & this.state.WindowSize.height < 850 ?  // for SmartPhone
                <Typography variant="body2">
                  次に、画面上部中央の<img src="/manual/img_ope_21_icon_map.png" alt="map" />をクリックして下さい。地図が表示されます。<br />
                </Typography> :
                this.state.WindowSize.width < 850 & this.state.WindowSize.height < 450 ?  // for SmartPhone
                <Typography variant="body2">
                  次に、画面上部中央の<img src="/manual/img_ope_21_icon_map.png" alt="map" />をクリックして下さい。地図が表示されます。<br />
                </Typography> : 
                <Typography variant="body2">
                  次に、画面右上の<img src="/manual/img_ope_21_icon_map.png" alt="map" />をクリックして下さい。地図が表示されます。<br />
                </Typography>
                }
            <Typography variant="body2">
              <br />
              地図上のアイコンをクリックすると情報を表示します。<br />
              <br />
              ※．定期的に位置情報は更新されません。最新の位置は地図を閉じて再度端末を選択して表示させて下さい。
            </Typography>
          </Paper>
        </Grid>

        {/* title-sub-3 */}
        <Grid item xs={12}>
          <Paper className={classes.paperTitleSub} elevation={0} square>
            <Typography variant="h6" id="link2">3.管理名編集機能</Typography>
          </Paper>
        </Grid>
        {/* row 3-1 */}
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {this.state.WindowSize.width > 750 & this.state.WindowSize.height > 450 ?  // for iPad/PC
            <Paper elevation={0} square >
              <img src="/manual/img_ope_31.png" alt="edit" className={classes.titleImage} />
            </Paper>
            : // for SmartPhone
            <Paper className={classes.paperImage} elevation={0} square >
              <img src="/manual/img_ope_31_xs.png" alt="multi" className={classes.titleImageSX2} />
            </Paper>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              管理名を任意に編集することができます。
              <br />
              <br />
              使用方法：<br /> 
              基本画面で編集したい端末の<img src="/manual/img_ope_31_icon_edit.png" alt="check" />をクリックして下さい。<br />
              「管理名 編集画面」が表示されますので任意の管理名を入力後に編集完了をクリックして下さい。<br />
              <br />
              ※．端末情報表示画面には即時反映されません。最長1分程度お待ち下さい。
            </Typography>
          </Paper>
        </Grid>

        {/* title-sub-4 */}
        <Grid item xs={12}>
          <Paper className={classes.paperTitleSub} elevation={0} square>
            <Typography variant="h6" id="link3">4.フィルター機能</Typography>
          </Paper>
        </Grid>
        {/* row 3-1 */}
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {this.state.WindowSize.width > 750 & this.state.WindowSize.height > 450 ?  // for iPad/PC
            <Paper elevation={0} square >
              <img src="/manual/img_ope_41.png" alt="filter" className={classes.titleImage} />
            </Paper>
            : // for SmartPhone
            <Paper className={classes.paperImage} elevation={0} square >
              <img src="/manual/img_ope_41_xs.png" alt="multi" className={classes.titleImageSX2} />
            </Paper>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              フィルターを任意に設定し端末一覧に表示する端末を指定できます<br />
              <br />
              使用方法：<br />
            </Typography>
            {this.state.WindowSize.width < 450 & this.state.WindowSize.height < 850 ?  // for SmartPhone
              <Typography variant="body2">
                基本画面上部中央の<img src="/manual/img_ope_41_icon_filter.png" alt="check" />をクリックして下さい。<br />
              </Typography> :
              this.state.WindowSize.width < 850 & this.state.WindowSize.height < 450 ?  // for SmartPhone
                <Typography variant="body2">
                  基本画面上部中央の<img src="/manual/img_ope_41_icon_filter.png" alt="check" />をクリックして下さい。<br />
                </Typography> :
                <Typography variant="body2">
                  基本画面右上の<img src="/manual/img_ope_41_icon_filter.png" alt="check" />をクリックして下さい。<br />
                </Typography>
            }
            <Typography variant="body2">
              「フィルター」画面が表示されますので表示したい項目を選択して下さい。<br />
            </Typography>
          </Paper>
        </Grid>

        {/* title-sub-5 */}
        <Grid item xs={12}>
          <Paper className={classes.paperTitleSub} elevation={0} square>
            <Typography variant="h6" id="link4">5.サインアウト</Typography>
          </Paper>
        </Grid>
        {/* row 3-1 */}
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {this.state.WindowSize.width > 750 & this.state.WindowSize.height > 450 ?  // for iPad/PC
            <Paper elevation={0} square >
              <img src="/manual/img_ope_51.png" alt="signout" className={classes.titleImage} />
            </Paper>
            : // for SmartPhone
            <Paper className={classes.paperImage} elevation={0} square >
              <img src="/manual/img_ope_51_xs.png" alt="multi" className={classes.titleImageSX2} />
            </Paper>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper className={classes.paperBody} elevation={0} square>
            <Typography variant="body2">
              基本画面右上の<img src="/manual/img_ope_51_icon_user.png" alt="check" />をクリックして下さい。<br />
              「サインアウト」をクリックするとサインアウトします。<br />
            </Typography>
          </Paper>
        </Grid>
        
      </Grid>
    </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Operation);