/*---------------------------------------------------
      CONTAINER COMPONENT: CheckRoot
---------------------------------------------------*/

/* react */
import React from 'react'

/* react-router/redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { GeneralCompornent } from '../../App'

/* action component */
import { isLoggedIn } from '../../actions/Account'

class CheckRoot extends React.Component {
  componentDidMount() {
    this.props.isLoggedIn()
    if (!this.props.login) {
      this.props.history.push('/login')
    }
  }

  componentDidUpdate() {
    if (!this.props.login) {
      this.props.history.push('/login')
    }
  }

  render() {
    let compornent = <br />
    if (!this.props.isFetching) {
      compornent = <GeneralCompornent />
    }
    return (
      <div>
        {compornent}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.account.isFetching,
    login: state.account.login
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    isLoggedIn: () => dispatch(isLoggedIn()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckRoot))
