/*---------------------------------------------------
      REDUCER COMPONENT: Account
---------------------------------------------------*/

/* action types */
import {
  IS_LOGGED_IN_REQUEST, IS_LOGGED_IN_RESPONSE, ON_CHANGE_USER, ON_CHANGE_PASSWORD, ON_CHANGE_NEW_PASSWORD,
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
  LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE, SESSION_ERROR, CLOSE_LOGIN_ALERT,
  REPLACE_PASSWORD_SHOW, REPLACE_PASSWORD_FIELD, REPLACE_PASSWORD_HIDE,
  REPLACE_PASSWORD_REQUEST, REPLACE_PASSWORD_SUCCESS, REPLACE_PASSWORD_FAILURE,
  REPLACE_RESULT_HIDE
} from '../../actions/Account'

/* initial state */
const initialState = {
  isFetching: false,
  user: "",                   // user ID being entered
  password: "",               // password being entered at login page
  newPassword: "",            // password being entered at passwordchange page
  login: false,               // login state
  needChangePassword: false,  // temprary password using state
  loginUser: [],              // login user info got from Amplify
  strVariant: "success",      // success/failure
  showInform: false,          // true = display success, false = undisplay success
  msgInform: "",              // text area for success/notice displaying

  showReplace: false,         // true = display password change page, false = undisplay
  oldReplacePassword: "",     // previos password that has changed
  newReplacePassword: "",     // new passowrd
  showResult: false,          // true = display result that password change, false = undisplay
}

/* Action and Previous state　→ Next state */
export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOGGED_IN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case IS_LOGGED_IN_RESPONSE:
      return Object.assign({}, state, {
        isFetching: false,
        login: action.authState,
        user: action.user
      })
    case ON_CHANGE_USER:
      return Object.assign({}, state, {
        user: action.user
      })
    case ON_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        password: action.password
      })
    case ON_CHANGE_NEW_PASSWORD:
      return Object.assign({}, state, {
        newPassword: action.password
      })
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        showInform: false
      });
    case LOGIN_SUCCESS:
      let login = false
      let needChangePassword = false

      if (action.loginUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        needChangePassword = true
      } else {
        login = true
      }

      return Object.assign({}, state, {
        login: login,
        needChangePassword: needChangePassword,
        loginUser: action.loginUser
      })
    case LOGIN_FAILURE:
      let showInform = false

      /* input error. only the case of display id ,password was not imputed, or id not available, wrong password */
      if ((action.err.code === 'NotAuthorizedException') || (action.err.code === 'UserNotFoundException') ||
        (action.err.code === 'UnexpectedLambdaException') || (action.err === 'Username cannot be empty')) {
        showInform = true;
      }

      return Object.assign({}, state, {
        showInform: showInform,
        strVariant: "failure",
        msgInform: "IDもしくはパスワードが間違っています。"
      })
    case CHANGE_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        showInform: false
      })
    case CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        login: true,
        needChangePassword: false
      })
    case CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        showInform: true,
        strVariant: "failure",
        msgInform: "パスワード変更に失敗しました。パスワードポリシーを満たしているか確認してください。"
      })
    case LOGOUT_REQUEST:
      return Object.assign({}, state, {
        showInform: false
      })
    case LOGOUT_SUCCESS:
      state = initialState;   // <- store information reset for login username & password clear
      return Object.assign({}, state, {
        login: false,
        needChangePassword: false,
        loginUser: []
      })
    case LOGOUT_FAILURE:
      return Object.assign({}, state, {
        showInform: true,
        strVariant: "failure",
        msgInform: "ログアウトに失敗しました。"
      })
    case SESSION_ERROR:
      return Object.assign({}, state, {
        /*
        when error occured on current session, initialized account info and move to login page.
        to omit re-enter, sate user is out of scope.
         */
        password: "",
        newPassword: "",
        login: false,
        needChangePassword: false,
        inputError: false,
        loginUser: []
      })
    case CLOSE_LOGIN_ALERT:
      return Object.assign({}, state, {
        showInform: false
      })
    case REPLACE_PASSWORD_SHOW:
      return Object.assign({}, state, {
        showReplace: true
      })
    case REPLACE_PASSWORD_FIELD:
      return Object.assign({}, state, {
        [action.key]: action.value
      })
    case REPLACE_PASSWORD_HIDE:
      return Object.assign({}, state, {
        showReplace: false
      })
    case REPLACE_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        showReplace: false
      })
    case REPLACE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        showResult: true,
        msgInform: "パスワード変更に成功しました。"
      })
    case REPLACE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        showResult: true,
        strVariant: "failure",
        msgInform: "パスワード変更に失敗しました。変更前のパスワードを確認し、時間をおいてからやり直して下さい。"
      })
    case REPLACE_RESULT_HIDE:
      return Object.assign({}, state, {
        showResult: false,
        msgInform: "",
        oldReplacePassword: "",
        newReplacePassword: ""
      })
    default:
      return state
  }
}
