/*---------------------------------------------------
      Material UI: general theme
---------------------------------------------------*/

import defaultTheme from './default';

export default {
  default: defaultTheme
}

export const overrides = {
  typography: {
    "fontFamily": "\"Noto Sans JP\", \"Ubuntu Condensed\", \"sans-serif\" ",
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
      '@media (max-width:600px)': {
        fontSize: '1.285rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
    subtitle2:{
      fontSize: '1.0rem',
    },
    button: {
      textTransform: "none"
    },
    caption: {
      fontSize: '0.55rem',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 42,
    }
  },
  props: {
    MuiTextField: {
      color: "primary",
      variant: "standard",
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiRadio: {
      color: "primary",
    },
    MuiSwitch: {
      color: "primary",
    },
  }
};