/*-----------------------------------------
      ACTION COMPONENT: account
  -----------------------------------------*/

/* aws */
import { Auth } from 'aws-amplify'

/* action types */
export const IS_LOGGED_IN_REQUEST = 'IS_LOGGED_IN_REQUEST';
export const IS_LOGGED_IN_RESPONSE = 'IS_LOGGED_IN_RESPONSE';
export const ON_CHANGE_USER = 'ON_CHANGE_USER';
export const ON_CHANGE_PASSWORD = 'ON_CHANGE_PASSWORD';
export const ON_CHANGE_NEW_PASSWORD = 'ON_CHANGE_NEW_PASSWORD';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_SUBMIT_REQUEST = 'PASSWORD_RESET_SUBMIT_REQUEST';
export const PASSWORD_RESET_SUBMIT_SUCCESS = 'PASSWORD_RESET_SUBMIT_SUCCESS';
export const PASSWORD_RESET_SUBMIT_FAILURE = 'PASSWORD_RESET_SUBMIT_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const SESSION_ERROR = 'SESSION_ERROR';
export const CLOSE_LOGIN_ALERT = 'CLOSE_LOGIN_ALERT';

export const REPLACE_PASSWORD_SHOW = 'REPLACE_PASSWORD_SHOW';
export const REPLACE_PASSWORD_FIELD = 'REPLACE_PASSWORD_FIELD';
export const REPLACE_PASSWORD_HIDE = 'REPLACE_PASSWORD_HIDE';
export const REPLACE_PASSWORD_REQUEST = 'REPLACE_PASSWORD_REQUEST';
export const REPLACE_PASSWORD_SUCCESS = 'REPLACE_PASSWORD_SUCCESS';
export const REPLACE_PASSWORD_FAILURE = 'REPLACE_PASSWORD_FAILURE';
export const REPLACE_RESULT_HIDE = 'REPLACE_RESULT_HIDE';


/* add the authorization to the request header */
export async function addAuthorizationHeader(header) {
  try {
    const sessionData = await Auth.currentSession()
    header.Authorization = sessionData.idToken.jwtToken
    return header
  } catch (err) {
    console.log(err)
    return 'Session error'
  }
}

/* action creators */
export function isLoggedIn() {
  return async (dispatch) => {
    dispatch(isLoggedInRequest())
    try {
      const data = await Auth.currentAuthenticatedUser()
      return dispatch(isLoggedInResponse(true, data.username))
    } catch (err) {
      console.log(err)
      return dispatch(isLoggedInResponse(false, null))
    }
  }
}

function isLoggedInRequest() {
  return {
    type: IS_LOGGED_IN_REQUEST
  }
}

function isLoggedInResponse(authState, user) {
  return {
    type: IS_LOGGED_IN_RESPONSE,
    authState,
    user
  }
}

export function onChangeUser(user) {
  return {
    type: ON_CHANGE_USER,
    user
  }
}

export function onChangePassword(password) {
  return {
    type: ON_CHANGE_PASSWORD,
    password
  }
}

export function onChangeNewPassword(password) {
  return {
    type: ON_CHANGE_NEW_PASSWORD,
    password
  }
}

export function clickLogin(user, password) {
  return async (dispatch) => {
    dispatch(loginRequest())
    try {
      const loginUser = await Auth.signIn(user, password);
      return dispatch(loginSuccess(loginUser))
    } catch (err) {
      console.log(err)
      return dispatch(loginFailure(err))
    }
  }
}

function loginRequest() {
  return {
    type: LOGIN_REQUEST
  }
}

function loginSuccess(loginUser) {
  return {
    type: LOGIN_SUCCESS,
    loginUser
  }
}

function loginFailure(err) {
  return {
    type: LOGIN_FAILURE,
    err
  }
}

export function clickChangePassword(user, newpass) {
  return async (dispatch) => {
    dispatch(changePasswordRequest())
    try {
      await Auth.completeNewPassword(user, newpass, {});
      return dispatch(changePasswordSuccess())
    } catch (err) {
      console.log(err)
      return dispatch(changePasswordFailure(err))
    }
  }
}

function changePasswordRequest() {
  return {
    type: CHANGE_PASSWORD_REQUEST
  }
}

function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  }
}

function changePasswordFailure(err) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    err
  }
}

/* FOR MAINTENANCE: password reset */
export function forgotPassword(username) {
  return async (dispatch) => {
    dispatch(forgotPasswordRequest())
    try {
      const data = await Auth.forgotPassword(username)
      return dispatch(forgotPasswordSuccess(data))
    } catch (err) {
      console.log(err)
      return dispatch(forgotPasswordFailure(err))
    }
  }
}

function forgotPasswordRequest() {
  return {
    type: PASSWORD_RESET_REQUEST
  }
}

function forgotPasswordSuccess(data) {
  return {
    type: PASSWORD_RESET_SUCCESS,
    data
  }
}

function forgotPasswordFailure(err) {
  return {
    type: PASSWORD_RESET_FAILURE,
    err
  }
}

/* FOR MAINTENANCE: new password set when passowrd reseted  */
export function forgotPasswordSubmit(username, code, new_password) {
  return async (dispatch) => {
    dispatch(forgotPasswordSubmitRequest())
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, new_password)
      return dispatch(forgotPasswordSubmitSuccess(data))
    } catch (err) {
      console.log(err)
      return dispatch(forgotPasswordSubmitFailure(err))
    }
  }
}

function forgotPasswordSubmitRequest() {
  return {
    type: PASSWORD_RESET_SUBMIT_REQUEST
  }
}

function forgotPasswordSubmitSuccess(data) {
  return {
    type: PASSWORD_RESET_SUBMIT_SUCCESS,
    data
  }
}

function forgotPasswordSubmitFailure(err) {
  return {
    type: PASSWORD_RESET_SUBMIT_FAILURE,
    err
  }
}

export function clickLogout() {
  return async (dispatch) => {
    dispatch(logoutRequest())
    try {
      const data = await Auth.signOut();
      return dispatch(logoutSuccess(data))
    } catch (err) {
      console.log(err)
      return dispatch(logoutFailure(err))
    }
  }
}

function logoutRequest() {
  return {
    type: LOGOUT_REQUEST
  }
}

function logoutSuccess(data) {
  return {
    type: LOGOUT_SUCCESS,
    data
  }
}

function logoutFailure(err) {
  return {
    type: LOGOUT_FAILURE,
    err
  }
}

export function sessionError() {
  return {
    type: SESSION_ERROR
  }
}

export function closeLoginAlert() {
  return {
    type: CLOSE_LOGIN_ALERT
  }
}

/* user password change(replace) */ 
export function replacePasswordShow() {
  return {
    type: REPLACE_PASSWORD_SHOW
  }
}

export function replacePasswordField(key, value) {
  return {
    type: REPLACE_PASSWORD_FIELD,
    key,
    value
  }
}

export function replacePasswordHide() {
  return {
    type: REPLACE_PASSWORD_HIDE
  }
}

/* old user password change to new one(replace) */
export function clickReplacePassword(oldpass, newpass) {
  return async (dispatch) => {
    dispatch(replacePasswordRequest())
    try {
      /* password change after got info from loggedin user */
      await Auth.currentAuthenticatedUser()
        .then(auth_user => {
          return Auth.changePassword(auth_user, oldpass, newpass);
        })
      return dispatch(replacePasswordSuccess())
    } catch (err) {
      console.log(err)
      return dispatch(replacePasswordFailure(err))
    }
  }
}

function replacePasswordRequest() {
  return {
    type: REPLACE_PASSWORD_REQUEST
  }
}

function replacePasswordSuccess() {
  return {
    type: REPLACE_PASSWORD_SUCCESS
  }
}

function replacePasswordFailure(err) {
  return {
    type: REPLACE_PASSWORD_FAILURE,
    err
  }
}

/* call to hide result when password changed */
export function replaceResultHide() {
  return {
    type: REPLACE_RESULT_HIDE
  }
}
